.nav a {
    padding: 0rem 1.5rem !important;
    color: #000;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
}

.navBar {
    width: 30px;
    fill: #1a1a1a;
}

.navbarToggle {
    border: none;
    /* Убирает рамку */
    background-color: transparent;
    /* Прозрачный фон */
}

.navbarToggle:focus,
.navbarToggle:active {
    outline: none;
    /* Убирает выделение при клике */
    box-shadow: none;
    /* Убирает тень */
}

.navbarToggle .navbar-toggler-icon {
    filter: invert(1);
    /* Инвертирует цвет и делает иконку белой */
}


.navbarToggle span {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}

@media (max-width: 992px) {
    .nav a {
        color: #fff;
        font-weight: normal;
        padding: 0rem !important;
    }
}