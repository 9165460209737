.image {
    display: block;
    object-fit: cover;
    width: 100%;
    /* position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    transform: translate(-50%, -50%);
    */
}

.wrapper {
    margin: 0px auto;
    /* background: rgb(233, 233, 233); */
    padding: 0px;
    height: 550px;
    overflow: hidden;
}

.mobileWrapper {
    margin: 0px auto;
    /* background: rgb(233, 233, 233); */
    padding: 0px;
    height: 350px;
    overflow: hidden;
}

.fullWidth {
    width: 100%;
    position: relative;
    margin-top: 0px;
    margin-bottom: 0px;
    height: 550px;
}

.slidList {
    visibility: visible;
    display: block;
    overflow: hidden;
    height: 100%;
    max-height: none;
    left: 0px;
    list-style: none !important;
    position: absolute;
    margin: 0 !important;
    padding: 0 !important;
    overflow-x: visible;
    overflow-y: visible;
    background-image: none;
    background-position: 0 0;
    text-indent: 0;
    top: 0;
}

.arrow {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.5);
    width: 40px;
    height: 40px;
    position: absolute;
    display: block;
    z-index: 100;
    border-radius: 50%;
}

.leftArrow {
    top: 50%;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 20, -20, 0, 1);
    left: 0px;
}

.leftArrow:before {
    content: "<";
    margin-left: -3px;
    font-size: 20px;
    color: rgb(255, 255, 255);
    display: block;
    line-height: 40px;
    text-align: center;
}

.rightArrow {
    top: 50%;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -60, -20, 0, 1);
    left: 100%;
}

.rightArrow:before {
    content: ">";
    margin-right: -3px;
    font-size: 20px;
    color: rgb(255, 255, 255);
    display: block;
    line-height: 40px;
    text-align: center;
}

.contentBox {
    text-align: center;
}

.textBox {
    width: auto;
    display: block;
    z-index: 1000;

    transition: all 0s ease 0s;
    min-height: 0px;
    min-width: 0px;
    border-width: 0px;
    letter-spacing: 0px;
    padding: .25rem;
}

.textBox span {
    font-size: 36px;
    width: auto;
    color: #FFFFFF;
    padding: 10px 20px !important;
}

.content {
    width: auto;
    display: block;
    z-index: 1000;
    background-color: rgba(23, 162, 184, .5);
    transition: all 0s ease 0s;
    min-height: 0px;
    min-width: 0px;
    border-width: 0px;
    letter-spacing: 0px;
    padding: .5rem;
}

.content span {
    font-size: 30px;
    font-weight: bold;
    color: #f0f0f0;
}

@media all and (max-width: 768px) {
    .wrapper {
        display: none;
    }
}