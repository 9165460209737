.itemContainer {
    position: relative;
    background-color: #fff;
    -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, .1);
    -moz-box-shadow: 0 0 15px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, .1);
    margin: 10px;
    margin-bottom: 30px;
    overflow: hidden;
}

.priceBox {
    margin: 0 auto;
}

.price {
    position: absolute;
    display: inline-block;
    z-index: 10;
    text-align: center;
    margin: 0px auto;
    top: 20px;
    padding: 2px 7px 1px 7px;
    border-radius: 6px;
    color: #FFFFFF;
    background-color: rgba(227, 58, 12, 1.00);
    font-size: 18px;
    font-weight: 700;
    top: 20px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.imgContainer {
    position: relative;
    overflow: hidden;
    text-align: center;
}

.imgContainerImg {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    -webkit-backface-visibility: hidden;
    height: auto;
    width: 100%;
    overflow: hidden;
}

.shortInfo {
    position: absolute;
    left: 0;
    bottom: 0;
    background: -webkit-linear-gradient(top, transparent, #000);
    background: linear-gradient(to bottom, transparent, #000);
    width: 100%;
    padding: 45px 10px 8px 5px;
    color: #fff;
    z-index: 1000;
}

.icon {
    font-size: 25px;
    display: inline-block;
    vertical-align: middle;
    font-weight: 400;
    font-style: normal;
    padding: 0;
    margin: 0;
}

.priceSup {
    font-size: 18px;
    position: relative;
    top: -5px;
}

.itemTitle {
    position: absolute;
    bottom: 0px;
    width: 100%;
}

.itemTitleH3 {
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
}

.itemTitleH3 a {
    color: #FFFFFF;
}

.itemTitleCity {
    font-size: 16px;
    font-weight: normal;
}

.itemTitleRating {
    font-size: 14px;
    margin-left: -3px;
}

.itemTitleRatingVoted {
    color: #f90;
}

.hr {
    width: 95%;
}