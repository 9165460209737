/* .box {
    margin: 0 auto;
    margin-top: 1rem;
    text-align: center;
}
.boxMobile {
    margin: 0 auto;
    text-align: left;
} */
.boxIcon {
    background: #ffffff;
    border-style: solid;
    border-color: #fcb100;
    border-width: 5px;
    padding: 50px;
    border-radius: 500px;
    display: inline-block;
    margin: 0 auto;
}

.icon {
    width: 64px;
    height: 64px;
    margin: 0 auto;
}

.title {
    font-size: 18px;
    /* font-weight: bold; */
    color: #333333;
    margin-top: .5rem;
    text-align: center;
}

.description {
    font-size: 14px;
    text-align: center;
}

.boxIconMobile {
    float: left;
    margin-bottom: 10px;
}

.iconMobile {
    width: 45px;
    height: 45px;
    fill: #fac855;
}

.titleMobile {
    font-size: 15px;
    font-weight: bold;
    color: #333333;
    padding-top: 10px;
    margin: 0px;
}