.wrapper {
    cursor: pointer;
    position: relative;
    display: block;
}

.guestForm {
    left: auto;
    right: -1px;
    top: calc(100% + 6px);
    padding: 0.5rem;
    width: 270px;
    position: absolute;
    background: #fff;
    -webkit-box-shadow: 0 4px 4px rgb(0 0 0 / 15%);
    -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
    box-shadow: 0 4px 4px rgb(0 0 0 / 15%);
    z-index: 250;
    cursor: default;
    color: #4F4F4F;
    border-radius: 0px 0px 10px 10px;
}

.guestFormMobile {
    width: 100%
}



.guestBox {
    float: left;
    color: #FFFFFF;
    padding-right: .5rem;
    height: calc(1.5em + .75rem + 2px);
    cursor: pointer;
}

.icon {
    float: left;
}

.text {
    float: left;
}

.iconAdult {
    width: 20px;
    fill: #FFFFFF;
    background-color: white;
    -webkit-mask-image: url(/icons/fontAwesome/male.svg);
    mask-image: url(/icons/fontAwesome/male.svg);
    -webkit-mask-size: 12px;
    mask-size: 12px;
    -webkit-mask-position: bottom;
    mask-position: bottom;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    height: 38px;
    position: relative;
    vertical-align: bottom;
    display: block;
    float: left;
}

.iconChildren {
    width: 18px;
    fill: #FFFFFF;
    background-color: white;
    -webkit-mask-image: url(/icons/fontAwesome/child.svg);
    mask-image: url(/icons/fontAwesome/child.svg);
    -webkit-mask-size: 16px;
    mask-size: 16px;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: bottom;
    mask-position: bottom;
    height: 38px;
    position: relative;
    vertical-align: bottom;
    display: block;
    float: left;
}

.iconPlus {
    float: right;
    width: 12px;
    height: 12px;
}

.quantityWrapper {
    background: #f8f9fa;
    display: flex;
    padding: 0.2rem;
    border-radius: 4px;
    justify-content: space-between;
    align-items: center;
}

.quantityWrapper:focus {
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, .5);
}

.quantityButt {
    height: 21px;
    width: 21px;
    background: #1fb25a;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    transition: background .2s ease-in-out, color .2s ease-in-out;
    cursor: pointer;
    user-select: none;
    flex-shrink: 0;
    border: 1px solid transparent;
}

.quantityBox {
    cursor: pointer;
}

.quantityValue {
    text-align: center;
    padding: 0px .5rem;
    width: auto;
    position: relative;
    line-height: 30px;
}

.childWrapper {
    border: 1px solid #8c8c8c;
    margin: .5rem 0px !important;
    background-color: #f8f9fa;
    line-height: 34px;
    border-radius: 4px;
}

.deleteChild {
    cursor: pointer;
}

.adult {
    display: inline-block;
    vertical-align: middle;
    height: 30px;
    line-height: 40px;
}

.adult::before {
    content: "";
    width: 20px;
    fill: #FFFFFF;
    background-color: white;
    -webkit-mask-image: url(/icons/fontAwesome/male.svg);
    mask-image: url(/icons/fontAwesome/male.svg);
    -webkit-mask-size: 12px;
    mask-size: 12px;
    -webkit-mask-position: bottom;
    mask-position: bottom;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    height: 36px;
    position: relative;
    vertical-align: bottom;
    display: block;
    float: left;
}

.childrens {
    display: inline-block;
    vertical-align: middle;
    height: 30px;
    line-height: 40px;
}

.childrens::before {
    content: "";
    width: 20px;
    fill: #FFFFFF;
    background-color: white;
    -webkit-mask-image: url(/icons/fontAwesome/child.svg);
    mask-image: url(/icons/fontAwesome/child.svg);
    -webkit-mask-size: 18px;
    mask-size: 18px;
    -webkit-mask-position: bottom;
    mask-position: bottom;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    height: 36px;
    position: relative;
    vertical-align: bottom;
    display: block;
    float: left;
}

.text {
    float: left;
    margin: 0;
    padding: 0px 5px;
    border: 0;
    font-size: 14px;
    font-weight: bold;
    color: #FFFFFF;
    vertical-align: baseline;
}