.price {
    font-size: 22px;
    font-weight: bold;
}

.boxIcon {
    float: left;
    margin-bottom: 5px;
}

.icon {
    width: 28px;
    float: left;
    height: 28px;
    margin: 0px 5px;
}

.iconText {
    font-weight: bold;
}