.single {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    opacity: 1;
    visibility: inherit;
    position: absolute;
    z-index: 20;
}
.artBoard {
    background-repeat: no-repeat;
    background-image: url(/img/sale/artboard.png);
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 4;
    transition: all 0s ease 0s;
    min-height: 0px;
    min-width: 0px;
    line-height: 21px;
    border-width: 0px;
    margin: 0px;
    padding: 0px;
    letter-spacing: 0px;
    font-size: 14px;
    left: 0px;
    top: 0px;
    visibility: visible;
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, -0.0025, 0, 0, 0, 1);
}
.icon {
    position: relative;
    z-index: 1000;
    width: 240px;
    height: 240px;
    margin: 0px 5px;
}
.textSale {
    font-size: 38px;
    font-weight: bold;
    padding: 5px 10px;
    margin: 0 auto;
    position: absolute;
    z-index: 1000;
    color: #FFFFFF;
    top: 65px;
}