.accordion {
}
.Timeline {
     position: relative;
     width: 100%;
     float: left;
     position: relative;
     margin-top: 0;
     margin-top: 4px;
 }
.header {
    padding: 10px 0px;
    background: #fff;
}
.header div[aria-expanded="false"] .iconTitle {
    width: 20px;
    background-color: #3cc8aa;
    -webkit-mask-image: url(/icons/plus.svg);
    mask-image: url(/icons/plus.svg);
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    height: 20px;
    position: relative;
    display: inline-block;
    margin: 0px 10px;
    float: left;
}
.header div[aria-expanded="true"] .iconTitle {
    width: 20px;
    background-color: #3cc8aa;
    -webkit-mask-image: url(/icons/minus.svg);
    mask-image: url(/icons/minus.svg);
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    height: 20px;
    position: relative;
    display: inline-block;
    margin: 0px 10px;
    float: left;
}
.line{
    position: absolute;
    width: 2px;
    display: block;
    top: 0px;
    bottom: 0px;
    margin-left: 30px;
    background: #10a399;
    z-index: 20;
}
.panel {
    position: relative;
    cursor: pointer;
    clear: both;
    padding: 0px 0px 0 24px;
    margin-bottom: 3px;
    background: #fff;
}
.title {
    padding-left: 10px;
    font-size: 18px;
}

.itemPanel[aria-hidden="false"] {
    -webkit-animation: scale-up-ver-top 0.4s linear both;
    -moz-animation: scale-up-ver-top 0.4s linear both;
    animation: scale-up-ver-top 0.4s linear both;
}

@keyframes scale-up-ver-top {
    0%{
        -webkit-transform: scaleY(.4);
        transform: scaleY(.4);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
    }
    100%{
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
    }
}