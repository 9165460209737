.formBox {
    box-shadow: 0 4px 4px rgb(0 0 0 / 15%);
    transition: top 3.3s ease-out, transform 3.3s ease-out;
}

.formBox label {
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    display: block;
    margin-bottom: 5px;
    text-align: center;
}

.sticky {
    position: fixed;
    top: 0px;
    width: 100%;
    margin: 0 auto !important;
    left: 0px;
    z-index: 200;
}

.check {
    width: 40px;
    height: 40px;
    background-color: #ffffff;
    -webkit-mask-image: url(/icons/check.svg);
    mask-image: url(/icons/check.svg);
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    position: relative;
    display: inline-block;
    margin: 0 10px;
}

.checkMobile {
    width: 25px !important;
    height: 25px !important;
}

.checkVertical {
    width: 30px;
    height: 30px;
    background-color: #ffffff;
    -webkit-mask-image: url(/icons/check.svg);
    mask-image: url(/icons/check.svg);
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    position: relative;
    display: inline-block;
}

.background {
    background-color: #10a399;
    border-radius: 10px;
}

.transparent {
    background-color: transparent;
    border-radius: 10px;
}

.label {
    font-weight: bold;
    color: #fff;
    text-align: center;
    margin-bottom: 0.5rem;
}

.buttonMain {
    color: #fff;
    background-color: #fcb100 !important;
    font-weight: normal !important;
    height: calc(1.5em + .75rem + 2px);
    margin: 0px !important;
    padding: .375rem 2.75rem !important;
    line-height: 1.5 !important;
    font-size: 1rem !important;
    border-color: #fcb100 !important;
}

.text {
    font-size: 23px;
    line-height: 15px;
    color: #ffffff;
}

.vtext {
    font-size: 20px;
    line-height: 20px;
    color: #ffffff;
    text-align: center;
}

.ageBox {
    display: block;
}

.nightIcon {
    width: 38px;
    height: 38px;
    position: relative;
    display: inline-block;
    padding: 12px 0px;
    font-weight: bold;
    font-size: 17px;
    color: #fff;
    background-size: 34px;
    background-repeat: no-repeat;
    background-position: top;
    background-image: url(/icons/calendar.svg);
}

.nightIcon {
    font-weight: bold;
    font-size: 14px;
}


@media all and (max-width: 992px) {

    .text {
        font-size: 16px;
    }

    .formBox label {
        font-weight: normal;
    }
}