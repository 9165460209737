.panel {
    margin-bottom: 0;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #ddd;
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
}
.heading {
    background-color: #fff;
    padding: 20px;
    color: #333;
    cursor: pointer;
}
.title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    color: inherit;
 }