.itemContainer {
    position: relative;
    background-color: #fff;
    -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, .1);
    -moz-box-shadow: 0 0 15px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, .1);
    margin: 10px;
    margin-bottom: 30px;
    overflow: hidden;
}

.priceBox {
    margin: 0 auto;
}

.price {
    position: absolute;
    display: inline-block;
    z-index: 10;
    text-align: center;
    margin: 0px auto;
    top: 20px;
    padding: 2px 7px 1px 7px;
    border-radius: 6px;
    color: #FFFFFF;
    background-color: rgba(227, 58, 12, 1.00);
    font-size: 18px;
    font-weight: 700;
    top: 20px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.ribbon {
    position: absolute;
    left: 10px;
    top: -5px;
    z-index: 9;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;
}

.ribbonSpan {
    font-size: 10px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    width: 100px;
    display: block;
    background: #79a70a;
    background: linear-gradient(#9bc90d 0, #79a70a 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    text-shadow: 1px 1px 2px rgba(0, 0, 0, .25);
    position: absolute;
    top: 19px;
    left: -21px;
}

.ribbonSpan::before {
    content: "";
    position: absolute;
    left: 0;
    top: 100%;
    z-index: -1;
    border-left: 3px solid #638908;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #638908;
}

.ribbonSpan::after {
    content: "";
    position: absolute;
    right: 0;
    top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid #638908;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #638908;
}


.ribbonPopularSpan {
    background: linear-gradient(#f14f3f 0, #cb4335 100%);
}

.ribbonPopularSpan::before {
    border-left: 3px solid #a3362a;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #a3362a;
}

.ribbonPopularSpan::after {
    border-left: 3px solid transparent;
    border-right: 3px solid #a3362a;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #a3362a;
}

.imgContainer {
    position: relative;
    max-height: 350px;
    overflow: hidden;
    text-align: center;
}

.imgContainerImg {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    -webkit-backface-visibility: hidden;
    height: auto;
    width: 100%;
    overflow: hidden;
}

.shortInfo {
    position: absolute;
    left: 0;
    bottom: 0;
    background: -webkit-linear-gradient(top, transparent, #000);
    background: linear-gradient(to bottom, transparent, #000);
    width: 100%;
    padding: 45px 10px 8px 5px;
    color: #fff;
    z-index: 1000;
}

.icon {
    font-size: 25px;
    display: inline-block;
    vertical-align: middle;
    font-weight: 400;
    font-style: normal;
    padding: 0;
    margin: 0;
}

.priceSup {
    font-size: 18px;
    position: relative;
    top: -5px;
}

.itemTitle {
    position: absolute;
    bottom: 0px;
    width: 100%;
}

.itemTitleH3 {
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
}

.itemTitleH3 a {
    color: #FFFFFF;
}

.itemTitleCity {
    font-size: 16px;
    font-weight: normal;
}

.itemTitleRating {
    font-size: 14px;
    margin-left: -3px;
}

.itemTitleRatingVoted {
    color: #f90;
}

.hr {
    width: 95%;
}