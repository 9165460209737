.tabs {}

.tabList {
    background-color: rgb(255, 255, 255);
    border-bottom: 1px solid rgb(221, 221, 221);
    display: flex;
    /* Используем Flexbox */
    list-style-type: none;
    /* Убираем отступы */
    margin: 0;
    padding: .5rem 0;
    /* Убираем внешние отступы */

}

.tab {
    cursor: pointer;
    padding: 0 .5rem;
}

.tabSelected {
    color: #1976d2;
    border-bottom: 1px solid #1976d2;
}

.tabPanel {
    display: none;
}

.active {
    padding: 1rem;
    background-color: rgb(255, 255, 255);
    display: block;
}