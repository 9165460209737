.header {
    background: #ffffff;
}

.header .logo {
    width: 100%;
}

.header .name {
    text-align: left;
    font-size: 27px;
    line-height: 27px;
    font-weight: 700;
    color: #000;
}

.header .desc {
    text-align: left;
    font-size: 18px;
    font-weight: bold;
    color: #000000;
}

.header .city {
    text-align: left;
    font-size: 18px;
    font-weight: bold;
    color: #000000;
}

.header .phone a {
    font-size: 18px;
    color: #973B50 !important;
    font-weight: bold;
    margin: 0px;
}

.header .phone .icon {
    width: 20px;
    height: 20px;
    background-color: #973B50;
    -webkit-mask-image: url(/icons/phone.svg);
    mask-image: url(/icons/phone.svg);
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    position: relative;
    display: inline-block;
    margin: 0px 10px;
}

.bgLogo {
    width: 100%;
}


.backCall {}


@media all and (max-width: 1204px) {
    .header .phone {
        font-size: 12px !important;
    }

    .header .logo {
        width: auto;
        max-height: 70px;
    }

    .bgLogo {
        width: auto;
        max-height: 70px;
    }
}

@media all and (max-width: 768px) {
    .header .logo {
        width: 100%;
        max-height: 100%;
    }

    .phoneContainer {
        flex-wrap: wrap !important;
    }

    .icon {
        display: none !important;
    }
}

@media all and (max-width: 576px) {
    .header .name {
        font-size: 20px;
        line-height: 20px;
    }

    .header .desc {
        font-size: 14px;
    }

    .header .city {
        font-size: 14px;
    }
}