.icon {
    position: fixed;
    right: 20px;
    bottom: 150px;
    width: 130px;
    z-index: 9000;
    cursor: pointer;
}

@media (max-width: 992px) {
    .icon {
        width: 75px !important;
        bottom: 200px !important;
    }
}

.iconCalcMobile {
    position: fixed;
    right: 20px;
    bottom: 150px;
    width: 80px;
    z-index: 7000;
    cursor: pointer;
}

.iconCalc {
    position: relative;
    fill: #FFFFFF;
    margin: 0 auto;
    margin-top: 15px;
    width: 130px;
    z-index: 7000;
    cursor: pointer;
}

.iconMobile {
    position: fixed;
    right: 20px;
    bottom: 150px;
    width: 65px;
    height: 65px;
    background-color: #fac855;
    display: block;
    border-radius: 65px !important;
    text-align: center;
    box-shadow: 0 19px 38px 0 rgb(34 36 43 / 30%);
    transform: scale(1);
}

.iconMobile div {
    position: relative;
    fill: #FFFFFF;
    margin: 0 auto;
    margin-top: 15px;
    width: 30px;
    z-index: 7000;
    cursor: pointer;

}

.ageBox {
    display: block;
}