.hotelBox {
    border-bottom: 1px solid #ccc;
}

.label {
    line-height: 34px;
    font-weight: bold;
    font-size: 15px;
}

.priceBox {
    position: absolute;
    top: 45%;
    margin: 0 auto;
    width: 100%;
    text-align: center;
}

.priceBox span {
    border-radius: 10px 10px;
    background-color: rgba(227, 58, 12, 1.00);
    padding: 10px;
    color: #fff;
    font-weight: bold;
}

.cityBox {
    position: absolute;
    bottom: 0px;
    padding: 5px 0px;
    background: rgba(0, 0, 0, .58);
}

.cityTitle {
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    line-height: 30px;
}

.icon {
    height: 34px;
    text-align: center;
    width: 34px;
    border-radius: 17px;
    flex-shrink: inherit;
    background-color: #6dab3c;
    color: #fff;
    line-height: 34px;
    font-size: 20px;
    margin-right: 1rem;
}

.iconHome {
    width: 16px;
    height: 16px;
    background-color: #FFFFFF;
    -webkit-mask-image: url(/icons/home.svg);
    mask-image: url(/icons/home.svg);
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    position: relative;
    display: inline-block;
    margin: 0px 10px;

}

.iconCity {
    width: 25px;
    height: 25px;
    background-color: #FFFFFF;
    -webkit-mask-image: url(/icons/location.svg);
    mask-image: url(/icons/location.svg);
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    position: relative;
    display: inline-block;
    margin: 0px 10px;

}

.iconTime {
    width: 16px;
    height: 16px;
    background-color: #FFFFFF;
    -webkit-mask-image: url(/icons/clock.svg);
    mask-image: url(/icons/clock.svg);
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    position: relative;
    display: inline-block;
    margin: 0px 10px;
}

.iconFood {
    width: 16px;
    height: 16px;
    background-color: #FFFFFF;
    -webkit-mask-image: url(/icons/food.svg);
    mask-image: url(/icons/food.svg);
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    position: relative;
    display: inline-block;
    margin: 0px 10px;
}



.itemContainer {
    background-color: #fff;
    -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, .1);
    -moz-box-shadow: 0 0 15px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, .1);
    margin: 0;
    margin-bottom: 30px;
}

.ribbon {
    position: absolute;
    left: 10px;
    top: -5px;
    z-index: 9;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;
}

.ribbonSpan {
    font-size: 10px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    width: 100px;
    display: block;
    background: #79a70a;
    background: linear-gradient(#9bc90d 0, #79a70a 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    text-shadow: 1px 1px 2px rgba(0, 0, 0, .25);
    position: absolute;
    top: 19px;
    left: -21px;
}

.ribbonSpan::before {
    content: "";
    position: absolute;
    left: 0;
    top: 100%;
    z-index: -1;
    border-left: 3px solid #638908;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #638908;
}

.ribbonSpan::after {
    content: "";
    position: absolute;
    right: 0;
    top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid #638908;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #638908;
}


.ribbonPopularSpan {
    background: linear-gradient(#f14f3f 0, #cb4335 100%);
}

.ribbonPopularSpan::before {
    border-left: 3px solid #a3362a;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #a3362a;
}

.ribbonPopularSpan::after {
    border-left: 3px solid transparent;
    border-right: 3px solid #a3362a;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #a3362a;
}

.imgContainer {
    position: relative;
    max-height: 350px;
    overflow: hidden;
}

.imgContainerImg {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    -webkit-backface-visibility: hidden;
    height: auto;
    width: 100%;
    overflow: hidden;
}

.shortInfo {
    position: absolute;
    left: 0;
    bottom: 0;
    background: -webkit-linear-gradient(top, transparent, #000);
    background: linear-gradient(to bottom, transparent, #000);
    width: 100%;
    padding: 45px 10px 8px 5px;
    color: #fff;
    z-index: 1000;
}

.price {
    float: right;
    font-size: 28px;
    font-weight: 700;
    display: inline-block;
}

.priceSup {
    font-size: 18px;
    position: relative;
    top: -5px;
}

.itemTitle {
    position: relative;
}

.itemTitleH3 {
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
}

.itemTitleCity {
    font-size: 16px;
    font-weight: normal;
}

.itemTitleRating {
    font-size: 14px;
    margin-left: -3px;
}

.itemTitleRatingVoted {
    color: #f90;
}

.hr {
    width: 95%;
}