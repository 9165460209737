.itemRelBox {
    position: relative;
    margin: 0px 10px 0px;
}
.relTitle {
    position: absolute;
    bottom: 0px;
    width: 100%;
    text-align: center;
    padding: 5px;
    font-size: 20px;
    font-weight: bold;
    color: #fff!important;
    margin: 0 auto;
    background-color: rgba(0,158,147,1.00);
}