.Post {
    text-align: center;
    position: relative;
}
.img {
    max-width: 100%;
    height: auto;
}
.body_post {
    position: absolute;
    top: 50%;
    width: 100%;
    background: rgba(16, 163, 153, .6);
    /* transform: translate(-50%, -50%); */
    /* -webkit-transition: cubic-bezier(0.68, -0.55, 0.27, 01.55) 320ms; */
    /* -moz-transition: cubic-bezier(0.68, -0.55, 0.27, 01.55) 320ms; */
    /* -ms-transition: cubic-bezier(0.68, -0.55, 0.27, 01.55) 320ms; */
    /* -o-transition: cubic-bezier(0.68, -0.55, 0.27, 01.55) 320ms; */
    /* transition: cubic-bezier(0.68, -0.55, 0.27, 01.55) 320ms; */
    cursor: pointer;
}

.post_content {
    padding: 1rem 1rem;
    position: relative;
}
.post_content h4 {
    color: #ffffff;
}
.overlay {
    position: absolute;
    top: 0px;
    right:0px;
    bottom: 0px;
    left: 0px;
}
.overlay:hover {
    background: rgba(16, 163, 153, .6);
}
@keyframes top {
    0% {
        opacity: 0;
        bottom: -80px
    }
    100% {
        opacity: 1;
        bottom: 0px

    }
}




@keyframes icon {
    0% {
        opacity: 0;
        transform: scale(0.0);
    }
    50% {
        opacity: 1;
        transform: scale(1.3) rotate(-02deg);

    }
    100% {
        opacity: 1;
        bottom: 0px;
    }
}