.hiddenBeforeAnimation {
    opacity: 0;
    /* Элемент изначально невидим */
    transition: opacity 0.3s ease, transform 0.3s ease;
    /* Плавный переход */
}

.afterAnimation {
    opacity: 1;
    /* Возвращаемся на место */
}