.art {
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    background-image: url(/img/sale/artboard.png);
}

.icon {
    width: 240px;
    height: 240px;
    position: absolute;
    top: calc(50% - 120px);
    left: calc(50% - 120px);
    z-index: 9999;
}