.mainBox {}

.mainBox li {
    margin-bottom: 1rem;
    list-style: none;
}

.address {
    width: 16px;
    height: 16px;
    background-color: #ffc107;
    -webkit-mask-image: url(/icons/location.svg);
    mask-image: url(/icons/location.svg);
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    position: relative;
    display: inline-block;
    margin: 0px 10px;

}

.time {
    width: 16px;
    height: 16px;
    background-color: #ffc107;
    -webkit-mask-image: url(/icons/clock.svg);
    mask-image: url(/icons/clock.svg);
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    position: relative;
    display: inline-block;
    margin: 0px 10px;
}

.phone {
    width: 16px;
    height: 16px;
    background-color: #ffc107;
    -webkit-mask-image: url(/icons/phone.svg);
    mask-image: url(/icons/phone.svg);
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    position: relative;
    display: inline-block;
    margin: 0px 10px;
}

.email {
    width: 16px;
    height: 16px;
    background-color: #ffc107;
    -webkit-mask-image: url(/icons/email-icon.svg);
    mask-image: url(/icons/email-icon.svg);
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    position: relative;
    display: inline-block;
    margin: 0px 10px;

}